/* eslint-disable */
import find from 'core-js/library/fn/array/virtual/find'
import findIndex from 'core-js/library/fn/array/virtual/find-index'
import assign from 'core-js/library/fn/object/assign'
import includes from 'core-js/library/fn/array/virtual/includes'
import symbolIterator from 'core-js/library/fn/symbol/iterator'
import padStart from 'core-js/library/fn/string/virtual/pad-start'
import SIncludes from 'core-js/library/fn/string/virtual/includes';
// import log10 from 'core-js/library/fn/math/log10'
import 'core-js/library/es6/symbol'
import 'core-js/library/es6'
import 'core-js/library/es7'
import 'core-js/modules/es6.symbol'
import "core-js/fn/symbol"
import 'core-js/modules/es6.array.iterator'
import 'core-js/modules/es6.math.log10'
import 'core-js/modules/es7.object.values'

Array.prototype.find || (Array.prototype.find = find)
Array.prototype.findIndex || (Array.prototype.findIndex = findIndex)
Array.prototype.includes || (Array.prototype.includes = includes)
String.prototype.padStart || (String.prototype.padStart = padStart)
String.prototype.includes || (String.prototype.includes = SIncludes)
// Math.prototype.log10 || (Math.prototype.log10 = log10)
Object.assign || (Object.assign = assign)
Symbol.iterator || (Symbol.iterator = symbolIterator)
